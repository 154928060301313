import genusIcon from "./logos/Genus.png";
import progenyIcon from "./logos/Progeny.png";
import worldBankIcon from "./logos/world_bank.svg";
import metisIcon from "./logos/metis.png";
import pennIcon from "./logos/Penn.svg";
import baeIcon from "./logos/bae.svg";

import LinkedInIcon from "./logos/linkedin.svg";
import TwitterIcon from "./logos/twitter.svg";
import GithubIcon from "./logos/github.svg";

export const LinkIcons = {
  "https://www.linkedin.com/in/matsteele/": LinkedInIcon,
  "https://github.com/matsteele/": GithubIcon,
  "https://twitter.com/urbanagrapher": TwitterIcon
};



export const OrgIcons = {
  "genus development partners": genusIcon,
  "progeny": progenyIcon,
  'metis': metisIcon,
  "world bank" : worldBankIcon,
  "UPenn": pennIcon,
  "bae": baeIcon,
};
