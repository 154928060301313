// import genusIcon from './logos/Genus.png';
// import progenyIcon from './logos/Progeny.png';
import AgilePlaceholder from '../agile_placeholder.png';

export const SkillsIcons = {
//   'genus development partners': genusIcon,
//   progeny: progenyIcon,
//   'world bank': worldBankIcon
};

const Skills = {
  under_construction: {
    image: AgilePlaceholder,
    descr: `Future iterations of this site will include this page.`,
    link: '',
    sub_title: 'growing one version at a time'
  }
};

export default Skills;
