import emotionIcon from "./emotion.png";
import javascriptIcon from "./javascript.png";
import jestIcon from "./jest.png";
import reactIcon from "./react.png";
import svgIcon from "./svg.svg";
import webpackIcon from "./webpack.svg";
import solidityIcon from "./solidity.png";
import truffleIcon from "./truffle.svg";
import metamaskIcon from "./metamask.png";
import ganacheIcon from "./ganache.svg";
import reduxIcon from "./redux.svg";
import d3Icon from "./d3.png";
import expressIcon from "./express.png";
import web3Icon from "./web3js.svg";
import nodeIcon from "./node.png";
import infuraIcon from "./infura.jpg";
import sassIcon from "./scss.svg";
import mochaIcon from "./mocha.svg";
import indesignIcon from "./indesign.png";
import mapboxIcon from "./mapbox.png";
import htmlIcon from "./html.png";
import pythonIcon from "./python.svg";
import pycryptoIcon from "./pycrypto.png";
import googlesheetsIcon from "./gsheets.png";
import seleniumIcon from "./selenium.png";
import illustratorIcon from "./illustrator.png";
import leafletIcon from "./leaflet.jpg";
import pandasIcon from "./pandas.png";
import scikitLearnIcon from "./scikit-learn.png";
import postgresqlIcon from "./postgresql.png";
import dockerIcon from "./docker.png";
import dashIcon from "./dash.png";
import plotlyIcon from "./plotly.png";

import rIcon from "./r.png";
import arcgisIcon from "./arcgis.png";


const libIcons = {
  solidity: solidityIcon,
  truffle: truffleIcon,
  javascript: javascriptIcon,
  metamask: metamaskIcon,
  ganache: ganacheIcon,
  web3: web3Icon,
  react: reactIcon,
  redux: reduxIcon,
  d3: d3Icon,
  svg: svgIcon,
  express: expressIcon,
  node: nodeIcon,
  infura: infuraIcon,
  sass: sassIcon,
  webpack: webpackIcon,
  jest: jestIcon,
  mocha: mochaIcon,
  emotion: emotionIcon,
  indesign: indesignIcon,
  illustrator: illustratorIcon,
  html: htmlIcon,
  python: pythonIcon,
  pycrypto: pycryptoIcon,
  googlesheets: googlesheetsIcon,
  selenium: seleniumIcon,
  mapbox: mapboxIcon,
  pandas: pandasIcon,
  leaflet: leafletIcon,
  scikitLearn: scikitLearnIcon,
  docker: dockerIcon,
  dash: dashIcon,
  plotly: plotlyIcon,
  postgresql: postgresqlIcon,
  r: rIcon,
  arcgis: arcgisIcon


};

export default libIcons;
